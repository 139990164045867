<script setup>
import { computed, ref, watchEffect } from 'vue'
import CircleSteps from '@shared/steps/CircleSteps.vue'
import VerifyDomain from '@components/domains/reserve/steps/VerifyDomain.vue'
import VerifyDomainAlaCarte from '@components/domains/reserve/steps/VerifyDomainAlaCarte.vue'
import ReservedDomain from '@components/domains/reserve/steps/ReservedDomain.vue'
import SelectDomain from '@components/domains/reserve/steps/SelectDomain.vue'
import { useDomainStore } from '@stores/domain'
import FadeModal from '@shared/modals/FadeModal.vue'
import { DialogTitle } from '@headlessui/vue'
import Icon from '@shared/Icon.vue'

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  alaCarte: {
    type: Boolean,
    required: true,
    default: true,
  },
  listingId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
  address: {
    type: String,
    required: true,
  },
  city: {
    type: String,
    required: true,
  },
  state: {
    type: String,
    required: true,
  },
  zip: {
    type: String,
    required: true,
  },
})

const domainStore = useDomainStore()
const isOpen = ref(props.open)
const fullAddress = computed(() => `${props.city}, ${props.state} ${props.zip}`)

watchEffect(() => {
  if (!isOpen.value) {
    if (domainStore.activeStep === 'Reserved') {
      window.location.reload()
      return
    }

    domainStore.$reset()
    return
  }

  domainStore.initDomain(props.alaCarte, props.listingId, props.name, props.email, props.phone, props.address, props.city, props.state, props.zip)
})
</script>

<template>
  <span @click="isOpen = true">
    <slot />
  </span>

  <FadeModal v-model="isOpen" width="5xl" height="xl">
    <div id="purchase-domain-modal" class="tw--mt-10 tw-w-1/2">
      <button v-if="domainStore.activeStep === 'Verify'" class="tw-flex tw-h-8 tw-items-center tw-text-lg tw-font-medium tw-text-primary-100 hover:tw-text-primary-300 focus:tw-bg-transparent" @click="domainStore.setActiveStep('Select')">
        <Icon class="tw--ml-2 tw--mr-1 tw-w-10" icon="ChevronLeftIcon" />

        <span>Back</span>
      </button>
      <div v-else class="tw-h-8" />
    </div>

    <DialogTitle as="h3" class="tw-mt-4 tw-flex tw-items-center tw-font-sans tw-text-2xl tw-font-medium tw-leading-6 tw-text-primary-100 sm:tw-text-3xl" v-text="domainStore.activeTitle" />

    <div class="tw-flex tw-flex-col">
      <div class="tw-mt-4 tw-flex tw-flex-col sm:tw-flex-row">
        <div class="tw-flex-1">
          <h4 class="tw-font-serif tw-text-2xl" v-text="address" />
          <div class="tw-text-xl" v-text="fullAddress" />
        </div>

        <div class="tw-mr-2 tw-mt-4 tw-justify-end tw-px-12 sm:tw-mt-0 sm:tw-w-1/4 sm:tw-px-0">
          <CircleSteps :steps="domainStore.steps" />
        </div>
      </div>

      <Transition
        mode="out-in"
        enter-active-class="tw-transition-opacity tw-duration-500 tw-ease-in"
        enter-from-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-transition-opacity tw-duration-500 tw-ease-out"
        leave-from-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div v-if="domainStore.activeStep === 'Select'">
          <SelectDomain />
        </div>

        <div v-else-if="alaCarte && domainStore.activeStep === 'Verify'">
          <VerifyDomainAlaCarte />
        </div>

        <div v-else-if="!alaCarte && domainStore.activeStep === 'Verify'">
          <VerifyDomain />
        </div>

        <div v-else-if="domainStore.activeStep === 'Reserved'">
          <ReservedDomain v-model="isOpen" />
        </div>
      </Transition>
    </div>
  </FadeModal>
</template>
