<script setup>
// Find more icons at https://heroicons.com
import {
  BookmarkIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
  CursorArrowRippleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid'

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/24/outline'

defineProps({
  icon: {
    type: String,
    required: true,
  },
})

const iconMap = {
  BookmarkIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
  CursorArrowRippleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  XCircleIcon,
  XMarkIcon,
}
</script>

<template>
  <component :is="iconMap[icon]" aria-hidden="true" />
</template>
