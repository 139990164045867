import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { DefineComponent } from 'vue'
import { LoginPage, ResetPasswordPage } from '@pages/index'
import Logout from '@components/auth/Logout.vue'
import SlwOnlineToggle from '@components/domains/SlwOnlineToggle.vue'
import PackagePublishedToggle from '@components/company-marketing/PackagePublishedToggle.vue'
import LoginModal from '@components/modals/LoginModal.vue'
import PurchaseDomainModal from '@components/modals/PurchaseDomainModal.vue'
import FeedbackBubble from '@components/support/FeedbackBubble.vue'
import { LinkButton, QuaternaryButton } from '@forms/index'

import.meta.glob<DefineComponent>([
  '../images/**',
  '!../images/favicon/**',
  '!../images/favicon2/**',
  '../fonts/**',
])

const setVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('load', setVh)
window.addEventListener('resize', setVh, { passive: true })

const app = createApp({})
  .use(createPinia())
  .component('LoginPage', LoginPage as DefineComponent)
  .component('Logout', Logout as DefineComponent)
  .component('ResetPasswordPage', ResetPasswordPage as DefineComponent)
  .component('LinkButton', LinkButton as DefineComponent)
  .component('QuaternaryButton', QuaternaryButton as DefineComponent)
  .component('FeedbackBubble', FeedbackBubble as DefineComponent)
  .component('PackagePublishedToggle', PackagePublishedToggle as DefineComponent)
  .component('LoginModal', LoginModal as DefineComponent)
  .component('PurchaseDomainModal', PurchaseDomainModal as DefineComponent)
  .component('SlwOnlineToggle', SlwOnlineToggle as DefineComponent)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN as string,
  debug: import.meta.env.VITE_SENTRY_DEBUG === 'true' ?? false,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE as string)
    : 0,
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE as string)
    : 0,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE as string)
    : 0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT as string,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: import.meta.env.VITE_SENTRY_SESSION_REPLAY_MASK_ALL_TEXT === 'true' ?? false,
      blockAllMedia: import.meta.env.VITE_SENTRY_SESSION_REPLAY_BLOCK_ALL_MEDIA === 'true' ?? false,
    }),
  ],
})

app.mount('#wrapper')
