<script setup>
import { ref, watchEffect } from 'vue'
import { useStorage } from '@vueuse/core'
import { useAuthStore } from '@stores/auth'
import { validationErrors } from '@modules/useHttp'
import { login } from '@modules/useAuth'
import LoadingSpinner from '@shared/LoadingSpinner.vue'
import { FormCheckbox, FormInput, LinkButton, PrimaryButton } from '@forms/index'
import ValidationMessage from '@shared/alerts/ValidationMessage.vue'
import { route } from '@/config'
import socialLogin from '@/enums/socialLogin'

const props = defineProps({
  open: {
    type: Boolean,
    default: true,
  },
  redirect: {
    type: String,
    default: '',
  },
  socialError: {
    type: Object,
    default: () => ({}),
  },
})

const authStore = useAuthStore()

const form = ref({
  email: '',
  password: '',
  remember: useStorage('remember_me', false),
  redirect: props.redirect,
})

const isOpen = ref(props.open)
const isSubmitting = ref(false)
const hasFailedAuth = ref(false)

const handleLogin = () => {
  isSubmitting.value = true
  login(form.value)
}

const handleSocialLogin = (type) => {
  try {
    isSubmitting.value = true

    const socialLoginRoutes = {
      [socialLogin.FACEBOOK]: route.auth.facebook,
      [socialLogin.X]: route.auth.x,
      [socialLogin.GOOGLE]: route.auth.google,
    }

    const url = socialLoginRoutes[type]

    if (!url) {
      console.error(`Invalid social login type ${type}`)
      isSubmitting.value = false
      return
    }

    window.location.href = url
  }
  catch (error) {
    console.error(error)
    isSubmitting.value = false
  }
}

watchEffect(() => {
  hasFailedAuth.value = false

  if (Object.keys(validationErrors.value).length > 0) {
    isSubmitting.value = false
  }

  if (Reflect.has(validationErrors.value, 'failed')) {
    hasFailedAuth.value = true
  }
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-y-5 tw-text-center">
    <div class="tw-flex tw-gap-x-3">
      <button :disabled="isSubmitting" class="tw-flex tw-h-10 tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 hover:tw-border-accent-300 hover:tw-bg-gray-50 disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50" @click="handleSocialLogin(socialLogin.FACEBOOK)">
        <img src="@icons/facebook.svg" class="tw-w-[20px] tw-font-medium" alt="Facebook logo">
      </button>

      <button :disabled="isSubmitting" class="tw-flex tw-h-10 tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 hover:tw-border-accent-300 hover:tw-bg-gray-50 disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50" @click="handleSocialLogin(socialLogin.X)">
        <img src="@icons/x.svg" class="tw-w-[20px] tw-font-medium" alt="X logo">
      </button>

      <button :disabled="isSubmitting" class="tw-flex tw-h-10 tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 hover:tw-border-accent-300 hover:tw-bg-gray-50 disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50" @click="handleSocialLogin(socialLogin.GOOGLE)">
        <img src="@icons/google.svg" class="tw-w-[20px] tw-font-medium" alt="Google logo">
      </button>
    </div>

    <ValidationMessage :message="socialError" field="social" class="tw-text-left" />

    <div class="tw-relative tw-w-full">
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
        <div class="tw-w-full tw-border-t tw-border-gray-300" />
      </div>

      <div class="tw-relative tw-flex tw-justify-center">
        <span class="tw-hidden tw-bg-white tw-px-2 tw-text-gray-500 sm:tw-block">Or continue with</span>
        <span class="tw-block tw-bg-white tw-px-2 tw-text-gray-500 sm:tw-hidden">Or</span>
      </div>
    </div>

    <form class="tw-flex tw-flex-col tw-gap-y-5" @submit.prevent="handleLogin">
      <FormInput v-model="form.email" field="email" label="Email address" :autofocus="isOpen" :has-error="hasFailedAuth" />
      <FormInput v-model="form.password" type="password" label="Password" :has-error="hasFailedAuth" />

      <ValidationMessage :message="validationErrors" field="failed" class="tw-text-left" />

      <div class="tw-flex tw-items-center">
        <div class="tw-flex-1">
          <FormCheckbox v-model="form.remember" label="Remember me" :store="true" />
        </div>

        <div class="tw-hidden sm:tw-block">
          <LinkButton @click="authStore.setCurrentPage('forgot-password')">
            Forgot your password?
          </LinkButton>
        </div>
      </div>

      <div class="tw-flex tw-h-12 tw-items-center tw-justify-center">
        <PrimaryButton v-if="!isSubmitting" as="submit" class="tw-w-full">
          Sign in
        </PrimaryButton>

        <LoadingSpinner v-else />
      </div>
    </form>

    <div class="sm:tw-hidden">
      <LinkButton @click="authStore.setCurrentPage('forgot-password')">
        Forgot your password?
      </LinkButton>
    </div>

    <ul class="tw-flex tw-justify-center tw-divide-x tw-text-xs tw-font-medium tw-text-gray-2-300">
      <li class="tw-pr-2">
        <a :href="route.privacy" class="tw-text-gray-2-300">Privacy Policy</a>
      </li>
      <li class="tw-pl-2">
        <a :href="route.terms" class="tw-text-gray-2-300">Terms & Conditions</a>
      </li>
    </ul>
  </div>
</template>
