<script setup>
import { computed } from 'vue'
import { useDomainStore } from '@stores/domain'
import { getConfirmation } from '@modules/useStripe'
import { formatUsd } from '@modules/useCurrency'
import { formatDate } from '@modules/useDate'
import { formatAddress } from '@modules/useListing'
import Icon from '@shared/Icon.vue'
import PrimaryButton from '@forms/PrimaryButton.vue'
import ErrorAlert from '@shared/alerts/ErrorAlert.vue'
import InfoAlert from '@shared/alerts/InfoAlert.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
})

const emit = defineEmits({ 'update:modelValue': null })
const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const domainStore = useDomainStore()
const reserveYears = computed(() => domainStore.reserveYears === 1 ? '1 Year' : `${domainStore.reserveYears} Years`)
</script>

<template>
  <div class="tw-mt-16 tw-rounded-lg tw-bg-white tw-px-4 tw-py-8 sm:tw-mt-8 sm:tw-px-16">
    <div class="tw-mx-auto tw-max-w-xl">
      <div class="tw-flex tw-flex-col tw-items-center">
        <div class="tw-mx-auto tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-temp-green-100">
          <Icon icon="CheckIcon" class="tw-h-8 tw-w-8 tw-text-temp-green-600" aria-hidden="true" />
        </div>

        <div class="tw-mt-6 tw-text-xl tw-font-medium">
          {{ domainStore.availableDomain.domain }} Reserved!
        </div>

        <p class="tw-mt-4">
          Congratulations! You have successfully reserved your domain name. <span class="tw-font-medium tw-text-accent-100">{{ domainStore.availableDomain.domain }}</span>
          is now the primary url for your listing at
          <span class="tw-font-medium">{{ formatAddress(domainStore.address.address, domainStore.address.city, domainStore.address.state) }}</span>
        </p>

        <InfoAlert class="tw-mt-4 tw-w-full">
          Please allow up to 10 minutes for the domain to begin working. A confirmation has been sent to your email on file at {{ domainStore.user.email }}.
        </InfoAlert>

        <ErrorAlert v-if="domainStore.reservationError" class="tw-mt-4 tw-w-full">
          {{ domainStore.reservationError }}
        </ErrorAlert>
      </div>

      <div v-if="domainStore.isAlaCarte" class="tw-mt-8 tw-flex tw-flex-col tw-gap-6 sm:tw-flex-row">
        <div class="tw-flex-1">
          <div class="tw-text-lg tw-font-medium">
            Order Info:
          </div>

          <ul>
            <li>Order No: <span class="tw-font-medium" v-text="getConfirmation.id" /></li>
            <li>Payment Date: <span class="tw-font-medium" v-text="formatDate(new Date())" /></li>
            <li>Payment Method: <span class="tw-font-medium">Credit Card</span></li>
            <li>Charge Amount: <span class="tw-font-medium" v-text="formatUsd((getConfirmation.amount / 100).toFixed(2))" /></li>
          </ul>
        </div>

        <div>
          <div class="tw-text-lg tw-font-medium">
            Your Order:
          </div>

          <ul>
            <li><span class="tw-font-medium">Domain Registration</span></li>
            <li><span class="tw-font-medium" v-text="domainStore.availableDomain.domain" /></li>
            <li><span class="tw-font-medium" v-text="reserveYears" /></li>
          </ul>
        </div>
      </div>

      <div class="tw-mt-8">
        <PrimaryButton class="tw-w-full" size="lg" @click="model = false">
          I'm Done
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>
