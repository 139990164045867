<script setup>
import { ref, watch } from 'vue'
import { useDomainStore } from '@stores/domain'
import { submitPaymentIntent } from '@modules/useStripe'
import { format, formatUsd, formatUsdYearly } from '@modules/useCurrency'
import { SelectMenu } from '@forms/index'
import Stripe from '@shared/Stripe.vue'
import InfoAlert from '@shared/alerts/InfoAlert.vue'

const domainStore = useDomainStore()

const isSubmitting = ref(false)
const submittingText = ref('Processing')

const { domain, price } = domainStore.availableDomain
const years = [
  { name: '1 Year', value: 1 },
  { name: '2 Years', value: 2 },
  { name: '3 Years', value: 3 },
  { name: '5 Years', value: 5 },
  { name: '10 Years', value: 10 },
]

const year = ref(years.find(year => year.value === domainStore.reserveYears))
const updatingYear = ref(false)

watch(year, async (year) => {
  updatingYear.value = true
  domainStore.setReserveYears(year.value)
  await domainStore.prepareDomain()
  updatingYear.value = false
})

const handleSubmit = async () => {
  isSubmitting.value = true
  const status = await submitPaymentIntent(domainStore.user)

  if (status.code >= 400) {
    isSubmitting.value = false
    return
  }

  submittingText.value = 'Registering Domain'
  await domainStore.registerDomain()
  domainStore.setActiveStep('Reserved')
}
</script>

<template>
  <div class="tw-mt-8 tw-flex tw-flex-col sm:tw-mt-0">
    <div class="tw-mt-8 tw-flex tw-font-medium">
      <div class="tw-flex-1 tw-text-lg tw-text-accent-100 sm:tw-text-2xl" v-text="domain" />
      <div :class="{ 'tw-opacity-50': updatingYear }" class="tw-text-right tw-text-2xl tw-font-bold sm:tw-text-3xl" v-text="formatUsd(domainStore.reservePrice)" />
    </div>

    <div class="tw-mt-8 tw-flex tw-flex-col tw-gap-x-4 sm:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-gap-x-3 tw-pr-4 sm:tw-w-1/2">
        <h3 class="tw-w-full">
          Domain Registration
        </h3>

        <div class="tw-flex tw-items-center tw-border-b tw-border-gray-400 tw-pb-4">
          <div class="tw-w-1/2 tw-font-medium">
            <SelectMenu v-model="year" :items="years" />
          </div>

          <div class="tw-flex-1 tw-text-right" v-text="formatUsdYearly(price)" />
        </div>

        <div class="tw-mt-8 tw-flex">
          <div class="tw-flex-1 tw-font-medium">
            Subtotal
          </div>

          <div :class="{ 'tw-opacity-50': updatingYear }" v-text="format(domainStore.reservePrice)" />
        </div>

        <div class="tw-mt-4 tw-flex tw-text-2xl tw-font-medium">
          <div class="tw-flex-1">
            Total (USD)
          </div>

          <div :class="{ 'tw-opacity-50': updatingYear }" v-text="format(domainStore.reservePrice)" />
        </div>

        <InfoAlert class="tw-mt-8">
          Please check the spelling - once a domain is purchased, it cannot be reversed!
        </InfoAlert>
      </div>

      <div class="tw-mt-8 tw-w-full tw-rounded-lg tw-bg-white tw-p-8 sm:tw-mt-0 sm:tw-w-1/2">
        <Stripe
          :class="{ 'tw-opacity-50': updatingYear }"
          :is-disabled="updatingYear"
          :is-submitting="isSubmitting"
          :submitting-text="submittingText"
          :handle-submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
