<script setup>
import { computed } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import Icon from '@shared/Icon.vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  items: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})
</script>

<template>
  <Listbox v-model="model" as="div">
    <ListboxLabel v-if="label" class="tw-block tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900" v-text="label" />
    <div class="tw-relative tw-w-2/3 sm:tw-w-full">
      <ListboxButton
        class="tw-shadow tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-200 sm:tw-text-sm sm:tw-leading-6"
      >
        <span class="tw-block tw-truncate" v-text="model.name" />
        <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
          <Icon icon="ChevronUpDownIcon" class="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="tw-transition tw-ease-in tw-duration-100" leave-from-class="tw-opacity-100" leave-to-class="tw-opacity-0">
        <ListboxOptions class="tw-shadow tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm">
          <ListboxOption v-for="(item, index) in items" :key="index" v-slot="{ active, selected }" as="template" :value="item">
            <li class="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9" :class="[active ? 'tw-bg-primary-200 tw-text-white' : 'tw-text-gray-900']">
              <span class="tw-block tw-truncate" :class="[selected ? 'tw-font-semibold' : 'tw-font-normal']" v-text="item.name" />

              <span v-if="selected" class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4" :class="[active ? 'tw-text-white' : 'tw-text-primary-200']">
                <Icon icon="CheckIcon" class="tw-h-5 tw-w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
