<script setup>
import { computed } from 'vue'
import Icon from '@shared/Icon.vue'
import LoadingDots from '@shared/LoadingDots.vue'
import { buttonSizes } from '@/config'

const props = defineProps({
  as: {
    type: String,
    default: 'button',
  },
  size: {
    type: String,
    default: 'md',
  },
  icon: {
    type: String,
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  submittingText: {
    type: String,
    default: 'Submitting',
  },
})

const sizeClasses = computed(() => buttonSizes[props.size])
</script>

<template>
  <button
    :type="as"
    :class="[sizeClasses, isSubmitting ? 'tw-pointer-events-none' : '']"
    :disabled="isDisabled"
    class="tw-inline-flex tw-items-center tw-justify-center tw-gap-x-2 tw-rounded-full tw-border tw-border-primary-200 tw-bg-white tw-font-medium tw-text-primary-200 hover:tw-border-secondary-100 hover:tw-text-secondary-100 hover:tw-shadow-md focus:tw-border-2 focus:tw-border-accent-300 focus:tw-shadow-md disabled:tw-pointer-events-none disabled:tw-border-gray-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-300"
  >
    <template v-if="icon">
      <Icon :icon="icon" class="tw-h-4 tw-w-4" />
    </template>

    <template v-if="isSubmitting">
      <span v-text="submittingText" />
      <LoadingDots color="tw-bg-primary-200" size="sm" />
    </template>
    <template v-else>
      <slot />
    </template>
  </button>
</template>
