import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { getConfirmation, getPaymentAmount, updatePaymentIntent } from '@modules/useStripe'
import { http } from '@modules/useHttp'
import { route } from '@/config'

const getDefaultState = () => ({
  user: { name: '', email: '', phone: '' },
  address: { address: '', city: '', state: '', zip: '' },
  activeStep: 'Select',
  steps: [
    { tab: 'Select', title: 'Search Domain Names', status: 'current', icon: 'CursorArrowRippleIcon' },
    { tab: 'Verify', title: 'Verify Domain Name', status: 'upcoming', icon: 'ShieldCheckIcon' },
    { tab: 'Reserved', title: 'Domain Order Confirmation', status: 'upcoming', icon: 'BookmarkIcon' },
  ],
  search: '',
  alaCarte: true,
  listingId: '',
  availableDomain: {
    available: false,
    domain: '',
    price: 0,
  },
  reservePrice: 0,
  reserveYears: 1,
  reservationError: '',
})

export const useDomainStore = defineStore('domain', () => {
  const state = ref(getDefaultState())

  const user = computed(() => state.value.user)
  const address = computed(() => state.value.address)
  const activeStep = computed(() => state.value.activeStep)
  const activeTitle = computed(() => state.value.steps.find(item => item.tab === activeStep.value).title)
  const steps = computed(() => state.value.steps)
  const listingId = computed(() => state.value.listingId)
  const search = computed(() => state.value.search)
  const isAlaCarte = computed(() => state.value.alaCarte)
  const availableDomain = computed(() => state.value.availableDomain)
  const reservePrice = computed(() => state.value.reservePrice)
  const reserveYears = computed(() => state.value.reserveYears)
  const reservationError = computed(() => state.value.reservationError)

  const setUser = (userName, userEmail, userPhone) => state.value.user = {
    name: userName,
    email: userEmail,
    phone: userPhone,
  }

  const setAddress = (listingAddress, listingCity, listingState, listingZip) => state.value.address = {
    address: listingAddress,
    city: listingCity,
    state: listingState,
    zip: listingZip,
  }

  const setActiveStep = (step) => {
    document.getElementById('purchase-domain-modal').scrollIntoView({ behavior: 'smooth' })
    state.value.activeStep = step
    const activeStepIndex = state.value.steps.findIndex(item => item.tab === step)

    if (activeStepIndex !== -1) {
      state.value.steps.forEach((step, index) => {
        step.status = index === activeStepIndex ? 'current' : index < activeStepIndex ? 'complete' : 'upcoming'
      })
    }
  }

  const setListingId = listingId => state.value.listingId = listingId

  const setAlaCarte = alaCarte => state.value.alaCarte = alaCarte

  const setReservePrice = price => state.value.reservePrice = price

  const setReserveYears = years => state.value.reserveYears = years

  const setReservationError = error => state.value.reservationError = error

  const setDomain = domain => state.value.search = domain

  const setDefaultDomain = address => setDomain(`${address.replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase()}.com`)

  const setAvailableDomain = (availableDomain) => {
    state.value.availableDomain = availableDomain
    setReservePrice(availableDomain.price)
  }

  const prepareDomain = async () => {
    if (isAlaCarte.value) {
      await updatePaymentIntent(route.domain.prepare, {
        domain: availableDomain.value.domain,
        years: reserveYears.value,
        prop_id: listingId.value,
      })
      setReservePrice(getPaymentAmount.value)
    }
  }

  const registerDomain = async () => {
    try {
      const { data } = await http().post(route.domain.register, {
        domain: availableDomain.value.domain,
        years: reserveYears.value,
        prop_id: listingId.value,
        pid: getConfirmation.value.id,
      })

      if (!data.success) {
        setReservationError(data.errors)
        console.error(data.errors)
      }
    }
    catch (error) {
      setReservationError('Network response was not ok. Unable to register domain. Please contact us for assistance.')
      console.error('Network response was not ok. Unable to register domain. Please contact us for assistance.')
    }
  }

  const initDomain = (isAlaCarte, listingId, userName, userEmail, userPhone, listingAddress, listingCity, listingState, listingZip) => {
    setUser(userName, userEmail, userPhone)
    setAddress(listingAddress, listingCity, listingState, listingZip)
    setAlaCarte(isAlaCarte)
    setListingId(listingId)
    setDefaultDomain(listingAddress)
  }

  const $reset = () => state.value = getDefaultState()

  return { state, user, address, activeStep, activeTitle, steps, search, isAlaCarte, availableDomain, reservePrice, reserveYears, reservationError, initDomain, prepareDomain, registerDomain, setActiveStep, setDomain, setAvailableDomain, setReserveYears, $reset }
})
